import { FC, useEffect } from "react";
import { Form } from "antd";
import _ from "lodash";
import { useParams } from "react-router-dom";

import { defaultTenantLogo } from "@ni/common/assets";
import { TENANT_COUNTRY, TENANT_CURRENCY, TIMEZONES } from "@ni/common/constants";
import { useReduxState, useToggleFeature } from "@ni/common/hooks";
import { FormValues, SelectOption } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, TenantSchemeForm } from "@ni/common/ui";
import { filterByDisplayValue } from "@ni/common/utils";
import { TenantApi } from "@ni/sdk/apis";
import {
  ChangeTenantRequest,
  ChangeTenantRequestCountryCodeEnum,
  RestTenantType,
  Tenant,
  TenantValue,
} from "@ni/sdk/models";

import styles from "./style.module.scss";

const tenantsServiceApi = new TenantApi();

export const TenantDetails: FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [tenants, setTenants] = useReduxState<Tenant[]>("tenants");
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");

  const { isDisabled } = useToggleFeature("TENANT_SHORT_NAME");

  useEffect(() => {
    if (!isLoading) {
      const domesticAreas = tenant?.tenantValues
        ?.find(val => val.fieldCode === "nic-domestic-country-rules")
        ?.value?.split(",") || [tenant.countryCode as string];
      const foreignAreas = tenant?.tenantValues
        ?.find(val => val.fieldCode === "nic-foreign-country-rules")
        ?.value?.split(",") || [tenant.countryCode as string];
      const timezone = tenant?.tenantValues?.find(val => val.fieldCode === "Time_zone")?.value?.toString() ?? "";
      const shortName = tenant?.tenantValues?.find(({ fieldCode }) => fieldCode === "short-name")?.value ?? "";

      form.setFieldsValue({
        tenantName: tenant?.name || "",
        tenantCountry: tenant?.countryCode || "",
        tenantCurrency: tenant?.defaultCurrency || "",
        fiType: tenant?.fiType || "",
        principalName: tenant?.principalName || "",
        domesticAreas,
        foreignAreas,
        Time_zone: timezone,
        "short-name": shortName,
      });
    }
  }, [tenant, isLoading, form]);

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const tenantId = parseInt(id ?? "0", 10);

    tenant?.tenantValues
      ?.filter(x => x.fieldCode?.endsWith("-member-id") || x.fieldCode?.endsWith("-principal_idn"))
      .forEach(({ fieldCode }) => {
        if (
          fieldCode &&
          !values[fieldCode]?.toString() &&
          tenant?.tenantValues?.find(item => item.fieldCode === fieldCode)
        ) {
          values[fieldCode] = "";
        }
      });

    const restFields = Object.entries(values)
      .filter(([key]) => !/^[a-z]+([A-Z][a-z]*)+$/.test(key))
      .map(([key, value]) => ({
        fieldCode: key,
        value,
      })) as TenantValue[];

    const tenantValues = [
      ...restFields,
      {
        fieldCode: "nic-domestic-country-rules",
        value: _.map(values["domesticAreas"] as string[]).join(","),
      },
      {
        fieldCode: "nic-foreign-country-rules",
        value: _.map(values["foreignAreas"] as string[]).join(","),
      },
    ];

    const requestBody: ChangeTenantRequest = {
      name: values["tenantName"] as string,
      defaultCurrency: values["tenantCurrency"] as string,
      countryCode: values["tenantCountry"] as ChangeTenantRequestCountryCodeEnum,
      fiType: values["fiType"] as RestTenantType,
      principalName: values["principalName"] as string,
      tenantValues,
    };

    tenantsServiceApi
      .editTenant(requestBody, tenantId)
      .then(res => {
        setTenant(res.data);
        setTenants(
          tenants.map(t => {
            if (res.data?.id === t.id) {
              return res.data;
            }

            return t;
          }),
        );
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      pageTitle="Tenant Details"
      form={form}
      submitHandler={onFinish as unknown as (values: FormValues) => void}
      size="md"
      formSize="md"
      submitLabel="Save"
      gap={24}
    >
      <div className={styles["logo-wrapper"]}>
        <p className={styles["logo-label"]}>Tenant logo</p>
        <img className={styles["tenant-logo"]} src={defaultTenantLogo} alt="tenant-logo" />
      </div>

      <NetworkForm.String
        formItemOptions={{
          name: "tenantName",
          label: "Tenant name",
          rules: [
            { required: true, message: "Tenant name is required" },
            { max: 32, message: "Tenant Name must have maximum 32 characters." },
            {
              pattern: /^[^\t\r{}]*$/,
              message: "Tenant Name contains not permitted characters as: “TAB”, `{', '}` ”.",
            },
          ],
        }}
      />

      <NetworkForm.String
        formItemOptions={{
          name: "short-name",
          label: "Tenant short name",
          rules: !isDisabled
            ? [
                { required: true, message: "Tenant short name is required" },
                { max: 5, message: "Tenant short name can only have 5 maximum characters." },
                {
                  pattern: /^[^\t\r{}]*$/,
                  message: "Tenant short name contains not permitted characters as: “TAB”, `{', '}` ”.",
                },
              ]
            : undefined,
        }}
      />

      <NetworkForm.DictionarySelect
        placeholder="Tenant country"
        filterOption={filterByDisplayValue}
        code={TENANT_COUNTRY}
        showSearch={true}
        formItemOptions={{ name: "tenantCountry", label: "Tenant country" }}
      />

      <NetworkForm.DictionarySelect
        placeholder="Tenant currency"
        filterOption={filterByDisplayValue}
        code={TENANT_CURRENCY}
        showSearch={true}
        formItemOptions={{ name: "tenantCurrency", label: "Local currency" }}
      />

      <NetworkForm.Select
        formItemOptions={{
          name: "Time_zone",
          label: "Financial institution time zone",
          tooltip:
            "Select the tenant's timezone to ensure correct local time for events, reminders, and accurate monthly limits/counters.",
        }}
        placeholder="Timezone"
        showSearch={true}
        optionList={TIMEZONES as SelectOption[]}
      />

      <TenantSchemeForm form={form} tenant={tenant} />
    </CustomFormWrapper>
  );
};
